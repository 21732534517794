export const enum Features {
  FullStory = 'fullstory',
  ExpDeductiblesV3 = 'exp-deductibles-v3',
  HideDownPayment = 'feat-hide-downpayment',
  ExpPCPLogin = 'exp-pcp-login-v2.1',
  ExpHPHeroFilters = 'exp-hp-herofilters-v4',
  ExpInstantDownPayment = 'exp-instant-downpayment',
  FeatOnboardingFee = 'feat-onboarding-fee',
  ExpPLPModelCards = 'exp-plp-modelcards-v2',
  ExpDisableAutoScrollDC = 'exp-disable-auto-scroll-dc',
  ExpRemoveDiscountLabel = 'exp-remove-discount-label',
  ExpAddSchufaNotice = 'exp-add-schufa-notice-v2',
  ExpSliderLinks = 'exp-sliderlinks',
  ExpStickyNavbar = 'exp-sticky-navbar',
  ExpInsuranceStep = 'exp-confirmation-insurance-step-v3',
  ExpPickupPoint = 'exp-pickup-point',
  ExpPLPReducedFilters = 'exp-plp-reducedfilters-v2',
  ExpDownpaymentLabel = 'exp-downpaymentlabel',
  ExpCustomization = 'exp-plp-customization',
  ExpPCPPayAsYouGo = 'exp-pcp-payasyougo',
  ExpComparisonV2 = 'exp-plp-comparison-v2',
}

// move the removed experiments here to clean up the cookie
export const RemovedExperiments = [
  'exp-insurance-step-v2',
  'exp-insurance-step',
  'exp-pcp-reducedmileage-v1',
  'exp-jobautolink',
  'exp-pdp-availabilitylabel-relaunch',
  'exp-pdp-carinfo2',
  'exp-newhero2',
  'exp-plp-popular-filters-v3',
  'exp-plp-modelcards',
  'exp-plpdateselector',
  'exp-relatedconfigs',
  'exp-pdp-leap-v2',
  'exp-pdp-leap-v1b',
  'exp-remove-sidebar-links',
  'exp-add-schufa-notice',
  'exp-pdp-availabilitylabel-relaunch',
  'exp-plp-popular-filters-v2',
  'exp-pcp-login-2',
  'exp-plp-incentive-labels',
  'exp-pdp-leap-v1',
  'exp-pcp-login-v3',
  'exp-pcp-login-v4',
  'exp-hp-herofilters-v3',
  'exp-reducedfilters',
];
